import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { OverwritingFareComponent } from './main/container/overwriting-fare/overwriting-fare/overwriting-fare.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './app.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './services/app.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ILLUMINAIRE_URL } from "../app/config/apiRouteConfig/index";
import { DutyService } from './services/duty/duty.service';
import { HeaderModule } from './main/header/header.module';
import { DriverService } from './services/driver/driver.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HubService } from './services/hub/hub.service';
import { TableModule } from 'primeng/table';
import { RiderService } from './services/rider/rider.service';
import { VehicleService } from './services/vehicle/vehicle.service';
import { SettingService } from './services/settings/setting.service';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { AudioPlayerComponent } from '../app/main/container/rides/audio-player/audio-player.component';
import { StepsModule } from 'primeng/steps';
import { MessageModule } from 'primeng/message';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormModule } from '../app/main/ticket/form/form.module';
import { FormsModule } from '@angular/forms';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { LoginComponent } from './main/login/login.component';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { GlobalErrorHandler } from './guards/global-error-handler';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SocketService } from './services/socket.service';
import { DutyModule } from './main/container/duty/duty.module';


const socketConfig: SocketIoConfig = { url: ILLUMINAIRE_URL };
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("393352348407-cctavkfvbphf4cuc3957n3nnv7r94uun.apps.googleusercontent.com")
  }
]);

export function provideConfig() {
  return config;
}

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    Providers?: Provider[];
  }
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AudioPlayerComponent,
    OverwritingFareComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SocketIoModule.forRoot(socketConfig),
    HeaderModule,
    BrowserAnimationsModule,
    FormsModule,
    TableModule,
    ButtonModule,
    DialogModule,
    StepsModule,
    DutyModule,
    MessageModule,
    InputSwitchModule,
    MultiSelectModule,
    FormModule,
    SocialLoginModule,
    Ng4LoadingSpinnerModule.forRoot(),
    AgmCoreModule.forRoot(
      {
        apiKey: 'AIzaSyCMmbclH3d8bR8FnQdU5S4NIY0AknFzZSI',
        libraries: ['places']
      }
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,

    },
    GoogleMapsAPIWrapper,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    CookieService,
    AppService,
    DutyService,
    DriverService,
    HubService,
    RiderService,
    VehicleService,
    SettingService,
    SocketService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [AudioPlayerComponent]
})
export class AppModule { }
