import {
  API_URL_LIST_DRIVERS ,
  API_URL_ADD_DRIVER,
  API_URL_EDIT_DRIVER,
  API_URL_RESET_PASSWORD_DRIVER,
  API_URL_GET_PUNCH_ID,
  API_URL_DRIVER_STATES_AUDIT,
  API_URL_ACTION_LIVE_RIDE,
  API_OVERSPEEDING_REPORT_PANEL,
  API_OVERSPEEDING_DRIVER,
  API_URL_GET_DRIVER_LOCATION,
  API_URL_GET_SUPPLY_REGION,
  API_ASSIGN_PHANTOM_RIDE,
  API_URL_DRIVER_BY_DRIVER_ID,
  API_URL_REJECT_RIDE_DENY_REQUEST,
  API_URL_MARK_BREAKDOWN_DRIVER,
  API_URL_GET_DRIVER_DETAILS,
  API_ASSIGN_BULK_PHANTOM_RIDE,
  API_URL_CANCEL_BREAKDOWN,
  API_URL_GET_DRIVER_QUEUE_AT_V3,
  API_URL_ADD_DRIVER_IN_QUEUE_V3,
  API_URL_LAST_RIDE_DETAIL
} from './../../config/apiRouteConfig/index';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class DriverService {
  getDriverLocation(driverId: any): any {
    return this._http
    .get(API_URL_GET_DRIVER_LOCATION+"/"+driverId+"/location").toPromise();
  }
 
  callListApi= true;

  constructor(private _http: HttpClient) { }

  getDrivers(): Observable<any>{
    return this._http
    .get(API_URL_LIST_DRIVERS);
  }

  addDriver(data){
    return this._http.post(API_URL_ADD_DRIVER, data);
  }

  editDriver(data): Observable<any>{
     return this._http.put(`${API_URL_EDIT_DRIVER}/${data.driverId}`, data);
  }
  resetPassword(data): Observable<any>{
    return this._http.post(`${API_URL_RESET_PASSWORD_DRIVER}/${data.driverId}`, data);
  }

  markBreakdown(data): Observable<any>{
    return this._http.post(`${API_URL_MARK_BREAKDOWN_DRIVER}`, data);
  }

  getDriversDetails(data){
    return this._http.get(`${API_URL_GET_PUNCH_ID}/${data}`)
  }
  getDriverStatesAudit(startDate,endDate){
    return this._http.get(`${API_URL_DRIVER_STATES_AUDIT}` + '?start=' + startDate + '&end=' + endDate);
  }

  getDriverOverSpeedingPanelData(from, to,driverId) : Observable<any> {
    return this._http.get(API_OVERSPEEDING_REPORT_PANEL + 'from/' + from + '/to/' + to + '/driverId/' + driverId);
  }
  assignPhantomRide(driverId,regionKey,reason){
    if(regionKey=='HUB'){
      regionKey= regionKey+'?isDriverReturnToHub=true'
    }
    regionKey = regionKey=='HUB?isDriverReturnToHub=true' ? regionKey + "&assignmentReason="+reason : regionKey + "?assignmentReason="+reason
    return this._http.post(API_ASSIGN_PHANTOM_RIDE+driverId+"/"+regionKey,{})
  }
  getDriverOverSpeedingStatsForRange( from, to ) {
    return this._http.get(API_OVERSPEEDING_DRIVER + '/from/' + from + '/to/' + to);
  }
  cancelPhantomRide(riderRequestId , driverId){
    let body ={
      action : 'CANCEL_PHANTOM_RIDE',
      driverId: driverId
    }
     return this._http.post(`${API_URL_ACTION_LIVE_RIDE}/${riderRequestId}/actions`, body)
  }
  cancelBreakdown(driverId){
    return this._http.get(`${API_URL_CANCEL_BREAKDOWN}/driver/remove/breakdown/${driverId}`)
 }
  getSupplyRegion(){
    return this._http.get(`${API_URL_GET_SUPPLY_REGION}`,{})
  }
  getDriverByDriverId(driverId){
    return this._http.get(`${API_URL_DRIVER_BY_DRIVER_ID}/${driverId}/on/duty`)
  }
  getLiveMapDriverByDriverId(driverId){
    return this._http.get(`${API_URL_DRIVER_BY_DRIVER_ID}/${driverId}/live/map`)
  }

  rejectAssignment(body){
    return this._http.post(`${API_URL_REJECT_RIDE_DENY_REQUEST}`,body)
  }

  getdriverQueueAtAirport(terminal){
    let url = "?terminal="+terminal;
    return this._http.get(`${API_URL_GET_DRIVER_QUEUE_AT_V3}`+url)
  }
  addDriverInQueue(driverId, pos,terminal){
     let url = "?driverId="+driverId + "&pos="+pos+"&terminal="+terminal;
     return this._http.post(`${API_URL_ADD_DRIVER_IN_QUEUE_V3}`+url,{})
  }
  getDriverDetails(driverId){
    return this._http.get(`${API_URL_GET_DRIVER_DETAILS}`+driverId)
  }
  assignBulkPhantomRide(driverIds,regionKey,reason){
    if(regionKey=='HUB'){
      regionKey= regionKey+'?isDriverReturnToHub=true'
    }
    regionKey = regionKey=='HUB?isDriverReturnToHub=true' ? regionKey + "&assignmentReason="+reason : regionKey + "?assignmentReason="+reason
    let url = API_ASSIGN_BULK_PHANTOM_RIDE+ regionKey;

    return this._http.post(url,driverIds)
  }
  getLastRideDetails(rideRequestId){ 
    let rqstId = parseInt(rideRequestId);
    return this._http.get(`${API_URL_LAST_RIDE_DETAIL}`+`?rideRequestId=${rqstId}`);
  }
}

