import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverwritingFareComponent } from './main/container/overwriting-fare/overwriting-fare/overwriting-fare.component';
import { LoginGuard } from './guards/login-route.guard';
import { CanActivateRouteGuard } from './guards/can-activate-route.guard';
import { LoginComponent } from './main/login/login.component';

const routes: Routes = [
  {
    path:'',
    loadChildren: () => import('./main/container/rides/rides.module').then(m => m.RidesModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'login',
    component:LoginComponent,
    canActivate:[LoginGuard]
  },
  {
    path:'driver',
    loadChildren: () => import('./main/container/driver/driver.module').then(m => m.DriverModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'livemap',
    loadChildren: () => import('./main/container/live-map/live-map.module').then(m => m.LiveMapModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'driver-on-duty',
    loadChildren: () => import('./main/container/duty/duty.module').then(m => m.DutyModule),
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'rides',
    loadChildren: () => import('./main/container/rides/rides.module').then(m => m.RidesModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'vehicle',
    loadChildren: () => import('./main/container/vehicles/vehicle.module').then(m => m.VehicleModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'riders',
    loadChildren: () => import('./main/container/rider/rider.module').then(m => m.RiderModule),
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'payments',
    loadChildren: () => import('./main/container/payments/payments.module').then(m => m.PaymentsModule),
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'reports',
    loadChildren: () => import('./main/container/reports/reports.module').then(m => m.ReportsModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'setting',
    loadChildren: () => import('./main/container/settings/settings.module').then(m => m.SettingsModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'journey-details',
    loadChildren: () => import('./main/container/journey-details/journey-details.module').then(m => m.JourneyDetailsModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'overspeeding',
    loadChildren: () => import('./main/container/overspeeding/overspeeding.module').then(m => m.OverspeedingModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path: 'overwriting_fare',
    component: OverwritingFareComponent
  },
  {
    path:'slots',
    loadChildren: () => import('./main/container/slots/slots.module').then(m => m.SlotsModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'rider-new',
    loadChildren: () => import('./main/container/rider-new/rider-new.module').then(m => m.RiderNewModule),
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'customer-support',
    loadChildren: () => import('./main/container/support/support.module').then(m => m.SupportModule),
    canActivate:[CanActivateRouteGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
