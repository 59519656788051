import { DutyService } from './../../../../services/duty/duty.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import Swal from 'sweetalert2';
import { PermissionForUsers } from 'src/app/config/apiRouteConfig/checkroles';
import { DriverService } from 'src/app/services/driver/driver.service';
import { Socket } from 'ngx-socket-io';
import { HostListener } from '@angular/core';
import { HubService } from 'src/app/services/hub/hub.service';
import { RideService } from 'src/app/services/ride/ride.service';
import { LiveMapService } from 'src/app/services/liveMapService/liveMapService.service';
import { RiderService } from 'src/app/services/rider/rider.service';


@Component({
  selector: 'app-driver-duty',
  templateUrl: './driver-duty.component.html',
  styleUrls: ['./driver-duty.component.css']
})

export class DriverDutyComponent implements OnInit, OnDestroy {
  pausedData;
  currentRideDenyRequestData;
  diplayConfirmPopup: boolean = false;
  imageUrl= 'https://s3.ap-south-1.amazonaws.com/storage.dev.blucgn.com/documents/1679909262104_IMG_20230327_145740762.png';
  selectedState = [];
  selectedRegionForState;
  manualNumber;
  load = false;
  selectedRegion: any;
  ridePanelFlag = {};
  selectedDispatchNumber;
  displayDispatcherContact = false;
  dispatcherList = [];
  drivers = [];
  showReviewPause: boolean = false;
  cols: any[];
  driverOnDutyStats = {
    returnToHubCount : 0,
    enrouteToHubCount : 0,
    onlineCount : 0,
    vehicleSelectCount : 0,
    tripStartedCount : 0,
    waitingForStartCount : 0,
    arrivedCount : 0,
    onRideCount : 0,
    waitingForKeyCount : 0,
    pausedCount : 0,
    autoPausedCount : 0,
    waitingForOnlineCount : 0
  }
  regionOptions = [];
  vehicleCatagories: { label: string; value: string; }[];
  totalPausedTimeFilters: { label: string; value: string; }[];
  pauseTimeFilters: { label: string; value: string; }[];
  vehicleQuery: string='';
  driverStatesOptions: SelectItem[];
  currentTime = new Date()
  hubList = [];
  timerIdStats = null;
  timerId = null;
  driverSsoId = null;
  displayCreatedRide: boolean = false;
  driverList=[]
  airports = [ {label:"Terminal 1", value:"TERMINAL_1_KIOSK"},
               {label:"Terminal 2", value:"TERMINAL_2_KIOSK"},
               {label:"Terminal 3", value:"TERMINAL_3_KIOSK"}]
  selectedAirport = "TERMINAL_3_KIOSK"
  selectedDriver = null
  showpunchIdInput=false
  punchId
  regions = []
  update;
  add;
  delete;
  classOnState = {
    OFFLINE: 'expandCol status red',
    AUTO_PAUSED: 'expandCol status tomato',
    PAUSED: 'expandCol status lightCoral',
    ON_RIDE: 'expandCol status green',
    TRIP_STARTED: 'expandCol status orange',
    ARRIVED: 'expandCol status blue',
    ONLINE: 'expandCol status black',
    VEHICLE_SELECT: 'expandCol status grey',
    COLLECT_CASH: 'expandCol status brown',
    WAITING_FOR_START: 'expandCol status pink',
    WAITING_FOR_KEY: 'expandCol status violet',
    CHECK_OUT: 'expandCol status white',
    WAITING_FOR_ONLINE: 'expandCol status teal',
    RETURN_TO_HUB: 'expandCol status redishPink',
    ENROUTE_TO_HUB: 'expandCol status darkRedishPink',
    AWAITING_ITEMS_CHECK: 'expandCol status maroon',
    "Request For Support": 'expandCol status req_for_support_color',
    "Request for RTH": 'expandCol status req_for_rth_color',
    "SOC picture requested": 'expandCol status soc_picture_request_color',
    "Request For Pause": 'expandCol status req_for_pause_color',
    "Request For BREAKDOWN": 'expandCol status req_for_breakdown_color'
  };
  seek=0;
  count=40;
  criteria = null;
  driverStateQuery = '';
  hubIdQuery = '';

  selectedReason: any = null;
  reasons= [
    { label: 'Low Battery', value: 'LOW_BATTERY' },
    { label: 'Driver continuosly in pause state', value: 'DRIVER_CONTINUOUSLY_IN_PAUSE_STATE' },
    { label: 'End of Shift', value: 'END_OF_SHIFT' },
    { label: 'Breakdown', value: 'BREAKDOWN' },
    { label: 'Supply Rebalancing', value: 'SUPPLY_REBALANCING' },
    { label: 'Driver in non-serviceable area', value: 'DRIVER_IN_NON_SERVICEABLE_LOCATION' },
    { label: 'Hub duty', value: 'HUB_DUTY' }
  ]

  entryReasons = {
    SUPPLY_REBALANCING: "Supply Rebalancing",
    CITY_TO_AIRPORT: "C-A Ride",
    ONLINE: "Online Driver",
    MANUAL: "Manual Assignment",
    HUB_LOGIN: "Hub Login"
  }

  showAirportQueue = false;
  airportQueuePoolingInterval = null;
  indexToAddDriver: any;
  driverData: any;
  
  constructor(private _loader: Ng4LoadingSpinnerService, private _dutyService: DutyService, private driverService: DriverService,
    private socket: Socket, private hubService : HubService, private _rideService: RideService,private riderService: RiderService,) {
  }

  ngOnInit() {
    this.getDriversOnDutyStats();
    this.getDriversOnDuty(false);
    this.getSupplyRegion();
    this.driverStatesOptions = [
       { label: 'Breakdown', value: 'BREAKDOWN' },
       { label: 'Charging', value: 'CHARGING' },
       { label: 'Supply Rebalancing', value: 'SUPPLY_REBALANCING' },
        { label: 'Request to Return', value: 'RETURN_TO_HUB' },
        { label: 'Enroute to Hub', value: 'ENROUTE_TO_HUB' },
        { label: 'Online', value: 'ONLINE' },
        { label: 'Checked In', value: 'VEHICLE_SELECT' },
        { label: 'Driver Enroute', value: 'TRIP_STARTED' },
        { label: 'Driver Assigned', value: 'WAITING_FOR_START' },
        { label: 'Driver Arrived', value: 'ARRIVED' },
        { label: 'Rider Picked Up', value: 'ON_RIDE' },
        { label: 'Checked Out', value: 'CHECK_OUT' },
        { label: 'Waiting For Key', value: 'WAITING_FOR_KEY' },
        { label: 'Offline', value: 'OFFLINE' },
        { label: 'Paused', value: 'PAUSED' },
        { label: 'Auto Paused', value: 'AUTO_PAUSED' },
        { label: 'Waiting For Online', value: 'WAITING_FOR_ONLINE' },
        { label: 'Request for Support', value: 'Request_for_Support' },
        { label: 'Request for Pause', value: 'Request_for_Pause' },
        { label: 'Request for Breakdown', value: 'Request_for_Breakdown' }
      ];  
      this.vehicleCatagories = [
        { label: 'All', value: '' },
        { label: 'Premium', value: 'PREMIUM' },
        { label: 'Classic', value: 'CLASSIC' }
       ]; 
       this.totalPausedTimeFilters = [
        { label: 'All', value: '' },
        { label: 'Greater than 2 hours ', value: 'GREATER_THAN_2_HOUR' },
       ];
    this.cols = [
        { field: 'driverId', header: 'Driver ID', isVisible: true },
        { field: 'displayName', header: 'Name', isVisible: true },
        { field: 'vehicleNumber', header: 'Vehicle Number', isVisible: true },
        { field: 'mobile', header: 'Phone Number', isVisible: true },
        { field: 'bookingCountForDay', header: 'Booking Count Today', isVisible: true },
        { field: 'totalEarningsForDay', header: 'Total Earnings Today', isVisible: true },
        { field: 'gpsTimestamp', header: 'Last GPS Ping', isVisible: true },
        { field: 'hub', header: 'Hub', isVisible: true },
        { field: 'soc', header: 'SOC', isVisible: true },
        { field: 'vehicleSoc', header: 'Vehicle Soc', isVisible: true },
        { field: 'dte', header: 'DTE', isVisible: true },
        { field: 'shiftEndTimestamp', header: 'Shift End Time', isVisible: true },
        { field: 'pauseTime', header: 'Paused Since', isVisible: true },
        { field: 'totalPausedTime', header: 'Total Paused Time', isVisible: true },
        { field: 'driverStateString', header: 'Status', isVisible: true },
        { field: 'action', header: 'Action', isVisible: this.checkPermission('DRIVERS_ON_DUTY_ACTIONS') }
      ];
      this.getAllActiveHubs();
  }

  ngOnDestroy() {
    clearInterval(this.timerIdStats);
    clearInterval(this.timerId);
    this.socket.removeListener('driverStatusEvent');
  }

  flatRiderDriver(driver) {
    driver.createdAt = driver.createdAt - Math.floor(Math.random() * 1000000);
    driver.actualAmount = Math.floor(Math.random() * 1000);
    driver.actionOn = 'rideState';
  }

  addAnalogyName(data) {
    if (data.driverStateString == 'ONLINE') { return 'Online'; }
    if (data.driverStateString == 'PAUSED') { return 'Paused'; }
    if (data.driverStateString == 'AUTO_PAUSED') { return 'Auto Paused'; }
    if (data.driverStateString == 'OFFLINE') { return 'Offline'; }
    if (data.driverStateString == 'ARRIVED') { return 'Driver Arrived'; }
    if (data.driverStateString == 'CHECK_OUT') { return 'Checked Out'; }
    if (data.driverStateString == 'ON_RIDE') { return 'Rider Picked Up'; }
    if (data.driverStateString == 'COLLECT_CASH') { return 'Collecting Cash'; }
    if (data.driverStateString == 'TRIP_STARTED') { return 'Driver Enroute'; }
    if (data.driverStateString == 'WAITING_FOR_START') { return 'Driver Assigned'; }
    if (data.driverStateString == "VEHICLE_SELECT") { return 'Checked In'; }
    if (data.driverStateString == "WAITING_FOR_KEY") { return 'Waiting For Key'; }
    if (data.driverStateString == "WAITING_FOR_ONLINE") { return 'Waiting For Online'; }
    if (data.driverStateString == "AWAITING_ITEMS_CHECK") { return 'Awaiting Items Check'; }
    if (data.driverStateString == "Request For Pause") { return 'Request For Pause'; }
    if (data.driverStateString == "Request For Support") { return 'Request For Support'; }
    if (data.driverStateString == "Request For BREAKDOWN") { return 'Request For BREAKDOWN'; }
    if (data.driverStateString == "Request for RTH") { return 'Request For RTH'; }
    if (data.driverStateString == "SOC picture requested") { return 'SOC picture requested'; }
    if (data.driverStateString == "RETURN_TO_HUB") {
      if (data.otherFlags && data.otherFlags.phantomRideDto && !data.otherFlags.phantomRideDto.isDriverReturnToHub)
        return 'Requested to reach ' + data.otherFlags.phantomRideDto.displayName
      else
        return 'Requested to Return to Hub';
    }
    if (data.driverStateString == "ENROUTE_TO_HUB") {
      if (data.otherFlags && data.otherFlags.phantomRideDto && !data.otherFlags.phantomRideDto.isDriverReturnToHub)
        return 'Enroute to ' + data.otherFlags.phantomRideDto.displayName
      else
        return 'Enroute to Hub';
    }
    return ""
  }

  addAnalogyReason(reason) {
    if(reason){
      switch (reason) {
        case "LOW_BATTERY": return "Low Battery";
        case "DRIVER_CONTINUOUSLY_IN_PAUSE_STATE": return "Continuously In Pause State";
        case "END_OF_SHIFT": return "End of Shift";
        case "ASSIGNED_BY_DISPATCHER": return "Assigned by Dispatcher";
        case "LOW_BATTERY_FIND_CHARGER_NEARBY": return "Low Battery";
        case "BREAKDOWN": return "Breakdown";
        case "NO_GPS": return "No GPS";
        case "PHANTOM_RIDE_ASSIGNMENT": return "Phantom ride Assigned";
        default: return reason.replaceAll("_", " ");
      }
    }
  
  }

  applyClass(status) {
    return this.classOnState[status];
  }

  specialClassOnTd(field, fieldValue) {
    if(fieldValue == 'Request For Support'){
    }
    return 'expandTd' + " " + this.applyClass(fieldValue);
  }

  getDriversOnDutyStats(){
    this._dutyService.getDriverOnDutyStats()
      .subscribe(
        (responseServer: any) => {
          if (responseServer.statusCode != 200) {
            console.log("There is some error while getting response for driver on duty stats " , responseServer.message);
          }
          else {
            this.driverOnDutyStats = responseServer.response;
          }
            if (!this.timerIdStats) {
                this.timerIdStats = setTimeout(() => {
                  this.getDriversOnDutyStats();
                  this.timerIdStats = null;
                }, 40 * 1000);
              }
        },
        err => {
            console.log(err);
            if (!this.timerIdStats) {
              this.timerIdStats = setTimeout(() => {
                this.getDriversOnDutyStats();
                this.timerIdStats = null;
              }, 40 * 1000); 
            }
        }
      );
  }

    getFormatedTime(totalPausedTime){
      const seconds = totalPausedTime % 60;
      const minutes = Math.floor((totalPausedTime / 60) % 60);
      const hours = Math.floor(totalPausedTime / 3600);
      let time = '';

      if(hours > 0){
        time += hours + ' hrs ';
      } 
      if(minutes > 0){
        time += minutes + ' min ';
      }
      if(seconds > 0){
        time += seconds + ' sec';
      }
      return time;
    }
  

  getDriversOnDuty(calledByTimer) {
    if(calledByTimer && (this.criteria != null || this.driverStateQuery != '' || this.hubIdQuery != '' || this.vehicleQuery!=''))
      return;
    if(calledByTimer){
      this.drivers = [];
      this.seek = 0;
    }
    this._loader.show();
    let seekQuery = '&seek='+this.seek;
    let countQuery = '&count='+this.count;
    let criteriaQuery = '';
    if (this.criteria) {
      criteriaQuery = '&criteria=' + this.criteria;
    }
    this._dutyService.getDriverOnDuty(seekQuery,countQuery,criteriaQuery,this.driverStateQuery, this.hubIdQuery,this.vehicleQuery)
      .subscribe(
        (responseServer: any) => {
          this._loader.hide();
          if (responseServer.statusCode != 200) {
            Swal.fire({
              title: 'Try Again',
              icon: 'info',
              text: responseServer.message
            });
          }
          else {
            const { response: driverList } = responseServer;
            driverList.forEach(driver => {this.drivers.push(driver)})
            
          }
          if (!this.timerId) {
              this.timerId = setTimeout(() => {
                if(!this.showAirportQueue)
                  this.getDriversOnDuty(true);
                this.timerId = null;
              }, 40 * 1000);
            }
        },
        err => {
          console.log(err);
          if (!this.timerId) {
            this.timerId = setTimeout(() => {
              if(!this.showAirportQueue)
                this.getDriversOnDuty(true);
              this.timerId = null;
            }, 40 * 1000);
          }
        });
  }
  
  checkPermission(permissionString: String) {
    return PermissionForUsers.checkPermission(permissionString);
  }

  driverOffline(driverId) {
    Swal.fire({
      title: 'Warning',
      html: `Are you sure you want to make driver offline.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this._loader.show();
        this._dutyService.offlineDriverAction(driverId)
          .subscribe(
            (response: any) => {
              this._loader.hide();
              if (response.statusCode != 200) {
                Swal.fire({
                  title: 'Try Again',
                  icon: 'error',
                  text: response.message
                });
              } else {
                this.drivers = this.drivers.map(data => {
                  if (data.driverId == driverId) {
                    data.driverStates = 'OFFLINE';
                    data.driverStateString = 'OFFLINE';
                  }
                  return data;
                });
                Swal.fire({
                  title: "SUCCESS",
                  icon: 'success',
                  text: response.message
                });
                this.getDriversOnDutyStats();
              }
            },
            err => {
              console.log(err);
            }
          )
      }
      return;
    });
  }

  assignPhantomRide() {
    if(this.selectedReason == null){
      Swal.fire({
          title: 'Opps...',
          icon: 'error',
          text: 'Please select Reason'
      });
      return
  }
    Swal.fire({
      title: 'Warning',
      html: 'Are you sure you want to assign a phantom Ride for ' + this.selectedRegion + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value)
        this.driverService.assignPhantomRide(this.driverSsoId, this.selectedRegion,this.selectedReason).subscribe(res => {
          if (res['statusCode'] == 200) {
            Swal.fire({
              title: 'Success',
              icon: 'success',
              text: res['message']
            });
            this.getDriversOnDuty(true)
            this.openCreateRideDialogBox(this.driverSsoId)
          } else {
            Swal.fire({
              title: 'Try Again',
              icon: 'error',
              text: res['message']
            });
          }
        })
    })
  }
  
  cancelPhantomRide(rideRequestId, driverId) {
    Swal.fire({
      title: 'Warning',
      html: `Are you sure you want to cancel the phantom ride?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value)
        this.driverService.cancelPhantomRide(rideRequestId, driverId).subscribe(res => {
          if (res['statusCode'] == 200) {
            Swal.fire({
              title: 'Success!',
              icon: 'success',
              text: res['message']
            });
            this.getDriversOnDuty(true)
          } else {
            Swal.fire({
              title: 'Try Again',
              icon: 'error',
              text: res['message']
            });
          }
        })
    })
  }

  openCreateRideDialogBox(driverSsoId) {
    this.driverSsoId = driverSsoId;
    this.displayCreatedRide = !this.displayCreatedRide;
  }

  select(region) {
    this.selectedRegion = region;
    if(region == 'HUB'){
      this.reasons= [
          { label: 'Low Battery', value: 'LOW_BATTERY' },
          { label: 'Driver continuosly in pause state', value: 'DRIVER_CONTINUOUSLY_IN_PAUSE_STATE' },
          { label: 'End of Shift', value: 'END_OF_SHIFT' },
          { label: 'Breakdown', value: 'BREAKDOWN' }
        ]
  }else {
      this.reasons= [
          { label: 'Supply Rebalancing', value: 'SUPPLY_REBALANCING' },
          { label: 'Driver in non-serviceable area', value: 'DRIVER_IN_NON_SERVICEABLE_LOCATION' }
        ]
  }
  }

  getSupplyRegion() {
    this.driverService.getSupplyRegion().subscribe(res => {
      if(res['response'].length > 0){
        let allRegionFilter = { 'label': 'All', 'value': ''};
        this.regionOptions.push(allRegionFilter);
      }
     
      res['response'].forEach(element => {
        let region = { 'label': element.regionKey, 'value': element.regionKey };
        this.regionOptions.push(region);

        if (element.regionKey != 'AIRPORT')
          this.regions.push(element.regionKey)
      });
    })
  }


  @HostListener("window:scroll")
  onWindowScroll() {
    //console.log("API call");
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    //console.log("API call");
   if (pos > max - 50 && this.drivers.length >= 4 && this.drivers.length >= this.seek + this.count) {
      this.seek = this.seek + this.count;
      //this.seek = this.seek + this.count;
      //this.getPastRideList();
      this.getDriversOnDuty(false);
    }
  }

  // Checks if airport queue is valid for this zone
  isAirportQueueApplicable() {
    let zoneId = localStorage.getItem("zoneId");
    if (zoneId == '1') {
      return true;
    }
    return false;
  }

  getDriversOnChange(event) {
    if (event.length > 3) {
      this.seek = 0;
      this.getDriversOnDuty(false);
      console.log(this.criteria);
      this.drivers = [];
    }
    if (event.length == 0) {
      this.drivers = [];
      this.seek = 0;
      this.criteria = null;
      this.getDriversOnDuty(false);
    }
  }
  onStateChange(driverStates,type)
  {
    if(driverStates){
      this.drivers = [];
      this.seek = 0;
      this.driverStateQuery = '';
      driverStates.forEach(state =>{
        this.driverStateQuery += state+",";
      });
      this.driverStateQuery = type == 'region' ? '&driverStates=' + driverStates + '&region=' + this.selectedRegionForState : '&driverStates='+this.driverStateQuery;
      this.getDriversOnDuty(false);
    }
    else{
      this.drivers = [];
      this.seek = 0;
      this.criteria = null;
      this.driverStateQuery = '';
      this.getDriversOnDuty(false);
    }  
  }

  onHubNameChange(hubIds)
  {
    
    if(hubIds){
      this.drivers = [];
      this.seek = 0;
      this.hubIdQuery = '';
      hubIds.forEach(hub =>{
        this.hubIdQuery += hub+",";
      });
      this.hubIdQuery = '&hubIds='+this.hubIdQuery;
      this.getDriversOnDuty(false);
      console.log(this.hubIdQuery);
    }
    else{
      this.drivers = [];
      this.seek = 0;
      this.criteria = null;
      this.hubIdQuery = '';
      this.getDriversOnDuty(false);
    }  
  }

  onVehicleChange(vehicleCategory){
    if(vehicleCategory && vehicleCategory !='ALL'){
      this.drivers = [];
      this.seek = 0;
      this.vehicleQuery = '&vehicleCategory='+vehicleCategory;
      this.getDriversOnDuty(false);
    }
    else{
      this.drivers = [];
      this.seek = 0;
      this.criteria = null;
      this.vehicleQuery = '';
      this.getDriversOnDuty(false);
    }  
  }

  onTotalPausedTimeChange(filter){
    if(filter && filter !='ALL'){
      this.drivers = [];
      this.seek = 0;
      this.vehicleQuery = '&totalPauseTimeFilter='+filter;
      this.getDriversOnDuty(false);
    }
    else{
      this.drivers = [];
      this.seek = 0;
      this.criteria = null;
      this.vehicleQuery = '';
      this.getDriversOnDuty(false);
    }  
  }

  getAllActiveHubs(){
    this.hubService.getAllActiveHubs().subscribe( (responseServer: any) => {
      if (responseServer.statusCode != 200) {
        console.log("There is some error while getting response for driver on duty stats " , responseServer.message);
      }
      else {
        let hubIdAndNameMap = responseServer.response;
        if (hubIdAndNameMap){
          Object.keys(hubIdAndNameMap).forEach(hubId => {
            this.hubList.push({label:hubIdAndNameMap[hubId],value:hubId})
          });
        }
      }
    },
    err => {
        console.log(err);
    })
  }
  driverOnline(driverId){
    Swal.fire({
      title: 'Warning',
      html: `Are you sure you want to make driver online.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this._loader.show();
        this._dutyService.onlineDriverAction(driverId)
          .subscribe(
            (response: any) => {
              this._loader.hide();
              if (response.statusCode != 200) {
                Swal.fire({
                  title: 'Try Again',
                  icon: 'error',
                  text: response.message
                });
              } else if (response.response == false) {
                Swal.fire({
                  title: 'Try Again',
                  icon: 'error',
                  text: "Can't mark Online.Please connect Tech team."
                });
              }else{
                this.drivers = this.drivers.map(data => {
                  if (data.driverId == driverId) {
                    data.driverStates = 'ONLINE';
                    data.driverStateString = 'ONLINE';
                  }
                  return data;
                });
                Swal.fire({
                  title: "SUCCESS",
                  icon: 'success',
                  text: response.message
                });
                this.getDriversOnDutyStats();
              }
            },
            err => {
              console.log(err);
            }
          )
      }
      return;
    });
  }
  openQueuePopUp(){
    this.showAirportQueue = true;
    this.getdriverQueueAtAirport();
  }
  getdriverQueueAtAirport(){
    this.driverService.getdriverQueueAtAirport(this.selectedAirport).subscribe(res=>{
      console.log(res)
      if(res['status'] == 'SUCCESS'){
        this.driverList = res['response'];
      }
    })
  }
  takeInput(index){
    this.showpunchIdInput =true;
    this.indexToAddDriver = index +1;
  }
  addDriverInQueue(){
    this.driverService.addDriverInQueue(this.punchId,this.indexToAddDriver,this.selectedAirport).subscribe(res=>{
      console.log(res)
      if(res['status'] == 'SUCCESS'){
        Swal.fire({
          title: "SUCCESS",
          icon: 'success',
          text: "Successfully Added"
        });
        this.cancelPunchIdEnterAction();
        this.getdriverQueueAtAirport();
      }else {
        Swal.fire({
          title: 'Try Again',
          icon: 'error',
          text: res['message']
        });
      }
    })
  }

  showAirportQueueModal() {
    this.airportQueuePoolingInterval = setInterval(() => this.getdriverQueueAtAirport(), 10000);
  }

  hideAirportQueueModal() {
    clearInterval(this.airportQueuePoolingInterval)
  }

  cancelPunchIdEnterAction() {
    this.showpunchIdInput = false;
    this.punchId = null;
  }

  callToDriver(event, data,_rideService, isOnRide) {
    if(event)
      event.stopPropagation();
    this.selectedDispatchNumber = localStorage.getItem('dispatcherNum');

    if (!this.selectedDispatchNumber) {
      this.callToDriverDialog(event)
    } else {
      if(_rideService)
      _rideService.callConnectToDriver(this.selectedDispatchNumber, data, isOnRide).subscribe((res => {
        this.displayDispatcherContact = false;
        Swal.fire({
          title: "Connecting!",
          text: "Your call is connecting to Driver ",
          timer: 3000
        }).then(result => {
          event.stopPropagation();
          this.displayDispatcherContact = false;
        })
      })) 
      else 
      this._rideService.callConnectToDriver(this.selectedDispatchNumber, data, isOnRide).subscribe((res => {
        this.displayDispatcherContact = false;
        Swal.fire({
          title: "Connecting!",
          text: "Your call is connecting to Driver ",
          timer: 3000
        }).then(result => {
          event.stopPropagation();
          this.displayDispatcherContact = false;
        })
      }))

    }
  }

  callToDriverDialog(event) {
    this._rideService.getDispatcherList().subscribe((res => {
      this.dispatcherList = res.response;
      this.ridePanelFlag = {};
      this.displayDispatcherContact = true;
      if (!this.selectedDispatchNumber && this.dispatcherList && this.dispatcherList.length > 0)
        this.selectedDispatchNumber = this.dispatcherList[0]
      if(event)
        event.stopPropagation();
    }))

  }

  hide() {
    this.displayDispatcherContact = null
  }

  updateDispatcherNum() {
    if (this.selectedDispatchNumber == 'Other')
      this.selectedDispatchNumber = this.manualNumber

    localStorage.setItem('dispatcherNum', this.selectedDispatchNumber);
    this.displayDispatcherContact = false;
  }

  viewRequest(driverData){
    this.driverData = driverData;
    this.showReviewPause = true;
  }

  getDriverDetails(driverId){
    this.driverService.getDriverDetails(driverId).subscribe(res=>{
      console.log(res)
      Swal.fire({
        title: 'Driver Phone Number',
        icon: 'info',
        text: res['response']['phoneNo']
      });
    })
  }

  checkIfExist(data, field){
    let isExist = false;
    if(!data){
      return isExist = false;
    }
   isExist =  data.hasOwnProperty(field); 
    
  return isExist
  }

  markBreakdown(rideDenyRequestData){
    rideDenyRequestData.requestStatus = "APPROVED";
    this._loader.show();
    this.driverService.markBreakdown(rideDenyRequestData)
    .subscribe(res =>{
      this._loader.hide();
      this.getDriversOnDutyStats();
      this.getDriversOnDuty(false);
      this.getSupplyRegion();
      Swal.fire({
        title: "Success",
        icon: "info",
        text: "Successfully approved"
      });
     this.hideConfirmPopup();
    }) 
  }

  hideConfirmPopup(){
    this.diplayConfirmPopup = false;
  }
  
  showConfirmPopup(data, rowData){
    data['ssoId'] = rowData['ssoId'];
    this.currentRideDenyRequestData = data;
    this.diplayConfirmPopup = true;
  }


  getIssueAnalogy(reasonKey){
   
    switch (reasonKey) {
      case "lMode": return "L-Mode";
      case "flatTyre": return "Flat Tyre";
      case "accident": return "Accident";
      case "lowSoc": return "Low SOC";
      case "unableToStart": return "Unable to Start / End ride";
      case "longDistance": return "Long distance pickup";
      case "other": return "Others";
      case "lowSocVerification": return "Low Soc Verification";
      case "SOC picture requested": return "SOC picture Requested";
    }
    return ""
  }
  findMinsFromNow(inputSocEventTimestamp) {
    let mins;
    let currentTimestamp = Date.now();
    mins = Math.floor((currentTimestamp - inputSocEventTimestamp) / 60000);
    return mins;
  }
  closeDialog(){
    this.drivers = []
    this.showReviewPause = false;
    this.getDriversOnDutyStats();
    this.getDriversOnDuty(false);
  }
  getAssigneeDetails(ssoId){
    if (ssoId == 0 || ssoId == "175398")
      return "SYSTEM"
    this.riderService.getUserDetailsBySsoId(ssoId).subscribe((res: any) => {
      Swal.fire({
        title: 'Assigned BY',
        icon: 'info',
        html: "Name :" + res.response.firstName + (res.response.lastName ? " " + res.response.lastName : " ") + "<br>Phone :" + res.response.phone
      });
    });
  }
  cancelBreakdown(rideRequestId, driverId){
    Swal.fire({
      title: 'Warning',
      html: `Are you sure you want to cancel the breakdown?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value)
        this.driverService.cancelBreakdown(driverId).subscribe(res => {
          if (res['statusCode'] == 200) {
            Swal.fire({
              title: 'Success!',
              icon: 'success',
              text: res['message']
            });
            this.getDriversOnDuty(true)
          } else {
            Swal.fire({
              title: 'Try Again',
              icon: 'error',
              text: res['message']
            });
          }
        })
    })
  }
  getRfsTime(timestamp){
    var today:any = new Date();
    let diffMs = (today - timestamp); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    let time = "";
    if(diffDays){
      time = diffDays + " D, ";
    }
    if(diffHrs){
      time = time + diffHrs + " Hrs, ";
    }
    if(diffMins){
      if(diffMins == 1){
        time = time + diffMins + " Min";
      }else{
        time = time + diffMins + " Mins";
      }
        
    }else{
      return "1 Min"
    }
    
      return time;
  }

  getBatterySourceAnalogus(key){ 
    switch (key) {  
      case "INTELLICAR": return "Intellicar"; break;
      case "MAP_MY_INDIA": return "MMI"; break;
      case "CITROEN": return "Citroen"; break;
    }
  }



}
